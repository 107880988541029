.horizontalBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 100%;
}

@media (max-width: 900px) {
  .horizontalBox {
    flex-direction: column;
    align-items: flex-start;
  }
}
