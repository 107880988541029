/* Modal Content/Box */
.modalErrorPercentageContent {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalErrorPercentage {
  width: 100%;
  /* padding-bottom: 30px; */
}

.modalSuccessConfirm {
  width: 142px;
  border-radius: 24px;
  padding: 12px 10px 12px 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: #e98a15;
  color: white;
  margin: 0;
  border-width: 0;
  cursor: pointer;
}

.modalSuccessConfirm:hover {
  opacity: 1;
  background: #ba6f12;
}

.modalErrorPercentageMainTitle {
  color: #63324b;
  font-size: 24px;
  font-weight: 600;
  margin-top: 0;
}

.modalErrorPercentageText {
  font-size: 16px;
  color: #333639;
  font-weight: 400;
  width: 80%;
}

.modalErrorPercentageBtnDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  height: 50px;
}

.modalErrorSaveChanges {
  width: 200px;
  height: 50px;
  border-radius: 24px;
  align-self: flex-end;
  /* padding: 12px 25px 12px 25px; */
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: #e98a15;
  color: white;
  border: none;
  margin: 0;
  cursor: pointer;
}

.modalErrorSaveChanges:hover {
  background-color: #ba6f12;
}
