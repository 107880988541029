.enhancedTableInput {
  color: #e98a15;
  font-family: Inter;
  margin-left: 5px;
  border-bottom-color: #e98a15;
  border-bottom-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  width: 90%;
  font-size: 16px;
}

.enhancedTableInput:focus {
  outline: none;
}

ion-icon.enhancedTableIcon {
  color: #e98a15;
}

.enhancedTableDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
}

.noEmployeesDiv {
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  width: fit-content;
}

.noEmployeesText {
  padding: 20px 20px 20px 20px;
  color: #303336;
  font-family: Inter, Semi Bold;
}

.noEmployeesMainDiv {
  width: 100%;
}

.noEmployeesNav {
  width: 100%;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
}

.noEmployeesUl {
  padding: 25px 0 25px 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.noEmployeesLi {
  font-family: Inter, Semi Bold;
  color: #303336;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;
}

.noEmployeesLi:first-of-type {
  padding-left: 30px;
}

.noEmployeesLi:last-of-type {
  padding-right: 30px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #303336;
  position: relative;
  bottom: 7px;
  left: 5px;
}
