.historialDiv {
  width: 100%;
  position: relative;
}

.mainContentHistorialDiv {
  min-height: 100vh;
  width: 100%;
}

.historialCardContainerDiv {
  width: 82%;
  position: relative;
  left: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.historialHeader {
  align-self: center;
  width: 100%;
  margin-left: 10%;
  padding-top: 120px;
}

.historialTitle {
  color: #303336;
  font-family: Inter, Semi Bold;
  font-size: 30px;
}

.historialParagraph {
  color: #303336;
  font-family: Inter, Regular;
  font-size: 16px;
}

.historialCardContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  align-self: flex-start;
}

.historialInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 15%;
  position: relative;
  z-index: 1;
  border-radius: 16px;
  box-shadow: 2px 2px 4px #00000029;
  height: 35px;
  overflow: hidden;
  border: 1px solid transparent;
  color: #303336;
  font-family: Inter, Regular;
  padding-left: 20px;
  z-index: 2;
}

.historialSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 15%;
  z-index: 1;
  border-radius: 16px;
  box-shadow: 2px 2px 4px #00000029;
  height: 35px;
  overflow: show;
  border: 1px solid transparent;
  padding-left: 20px;
  z-index: 2;
  padding-right: 10px;
  cursor: pointer;
}

.historialSelectFocus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 15%;
  z-index: 1;
  border-radius: 16px;
  box-shadow: 2px 2px 4px #00000029;
  height: 35px;
  overflow: show;
  padding-left: 20px;
  z-index: 2;
  padding-right: 10px;
  cursor: pointer;
  border: 1px solid #e98a15;
}

.historialSelectInput {
  color: #757575;
  font-family: Inter, Regular;
  font-size: 14px;
  border: none;
  width: 100%;
}

.historialSelect:focus {
  outline: none;
  border: 1px solid #e98a15;
  border-radius: 16px;
}

.historialInput:focus {
  outline: none;
  border: 1px solid #e98a15;
  border-radius: 16px;
}

.historialInput::placeholder {
  color: #757575;
}

.historialSelect:focus {
  outline: none;
}

.historialPicker:focus {
  outline: none;
}

.historialDivIcon {
  position: absolute;
  top: 21px;
  right: 10px;
  z-index: 5;
}

.historialTableDiv {
  width: 90%;
  margin-top: 30px;
  margin-bottom: 80px;
}

.historialBorrarDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 19%;
  position: relative;
  z-index: 1;
  margin: 10px 0px 10px 0px;
  height: 35px;
  padding-left: 10px;
}

.historialBorrarText {
  font-family: Inter, Semi Bold;
  color: #e98a15;
  font-weight: bold;
  cursor: pointer;
}

.historialBorrarText:hover {
  color: #ba6f12;
}

.historialPicker {
  border-color: transparent;
  position: relative;
  z-index: 1;
  margin: 14.25px 0px 10px 0px;
  border-radius: 16px;
  box-shadow: 2px 2px 4px #00000029;
  height: 30px;
  overflow: hidden;
  border: 1px solid transparent;
  color: #303336;
  font-family: Inter, Regular;
  padding-bottom: 3px;
  padding-left: 20px;
  background: white;
  cursor: pointer;
}

.historialPicker:focus {
  outline: none;
  border: 1px solid #e98a15;
  border-radius: 16px;
}

ion-icon {
  color: #303336;
  cursor: pointer;
}

.historialShiftOptions {
  position: absolute;
  top: 40px;
  width: 100%;
  left: 0px;
  z-index: 100;
  background-color: white;
  box-shadow: 2px 2px 4px #00000029;
  border-radius: 16px;
}

.historialShiftNone {
  display: none;
}

.historialShiftOptionsParagraph {
  color: #6a6a6a;
  font-family: Inter, Regular;
  margin-left: 10px;
  padding: 10px;
}

.historialShiftOptionsParagraph:hover {
  background-color: #f3f3f3;
}

.historialColaboradoresInput {
  border: none;
  color: #6a6a6a;
  font-family: Inter, Regular;
}

.historialColaboradoresInput:focus {
  outline: none;
}

.historialColabOptions {
  position: absolute;
  top: 40px;
  width: 100%;
  min-height: 50px;
  max-height: 150px;
  overflow-y: scroll;
  left: 0px;
  z-index: 100;
  background-color: white;
  box-shadow: 2px 2px 4px #00000029;
  border-radius: 10px;
}

.historialPickerDiv {
  position: absolute;
  top: 0;
  left: 0;
}

.historialNoMovements {
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  width: fit-content;
  color: #303336;
  font-size: 20px;
  padding: 25px;
  align-self: flex-start;
  margin-left: 5%;
}

.historialMainDiv {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 580px;
}

.historialDivTable {
  width: 100%;
  margin-bottom: 5%;
  margin-top: 2%;
}
