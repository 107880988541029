html {
  scroll-behavior: smooth;
}

.landingMain {
  /* margin-top: 90px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: Inter, bold;
  overflow-x: hidden;
}

.firstSectionBack {
  background-color: #f5f5f5;
  padding-bottom: 20px;
}

.landingLoginBtn {
  width: 142px;
  border-radius: 24px;
  padding: 12px 10px 12px 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: #e98a15;
  color: white;
  margin: 0;
  border-width: 0;
  cursor: pointer;
  height: 45px;
}

.landingLoginBtn:hover {
  opacity: 1;
  background: #ba6f12;
}

.landingLoginLink {
  font-family: Inter, Semi Bold;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: white;
}

@media (max-width: 800px) {
  /* suppressing navbar */
  .firstSectionBack nav {
    display: none;
    height: 0;
  }
  .landingMain {
    margin-top: 0;
  }

  /* changing hero highlight & layout */
  .heroHeader::after {
    content: '';
    position: absolute;
    background-image: url('../../assets/tipie-vectores-01.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 140px;
    width: 140px;
    margin-top: -80px;
    top: 10px;
    left: 10px;
  }
}

