.modalActionBar {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-content: flex-end;
  align-items: center;

  margin: 30px 15px 0px 0px;
  width: 100%;

  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
}

.modalBtnPrimary,
.modalBtnSecondary {
  all: unset;
  background-color: white;
  padding: 16px 32px;
  margin: 8px;
  border-radius: 24px;
  opacity: 1;
  cursor: pointer;
}

.modalBtnPrimary {
  color: white;
  background-color: #e98a15;
  position: relative;
}

.modalBtnPrimary:hover {
  background-color: #ba6f12;
}

.modalBtnPrimary:active {
  background-color: #95580c;
}

.modalBtnSecondary {
  color: #e98a15;
  border: 1px solid #e98a15;
  font-family: Inter, Semi bold;
}

.modalBtnSecondary:hover {
  /* Diseño pide mismos colores que default. Asumo error */
  color: #ba6f12;
  border-color: #ba6f12;
}

.modalBtnSecondary:active {
  color: #95580c;
  border-color: #95580c;
}

.modalBtnDisabled.modalBtnPrimary {
  background-color: #d1d1d1;
  color: white;
  cursor: not-allowed;
}

.modalBtnDisabled.modalBtnSecondary {
  background-color: white;
  color: #cfcfcf;
  border-color: #cfcfcf;
  cursor: not-allowed;
}
