.heroHeader {
  height: 800px;
  padding-left: 50px;
  position: relative;
  background-size: cover;
}

.heroTextDiv {
  position: absolute;
  bottom: 20px;
}

.heroHeader h1 {
  text-align: left;
  font-size: 50px;
  width: fit-content;
  margin: 5px;
  padding: 5px;
  letter-spacing: 0px;
  color: #E17400;
  text-shadow: inset 0px 3px 6px #00000029, 50px 50px 50px #00000029;
  opacity: 1;
  background-color: white;
}

.heroHeader img {
  max-height: 35px;
  padding-right: 10px;
}

.heroHeader p {
  font-weight: bold;
  color: #FFFFFF;
  margin: 5px;
  padding: 10px;
  width: fit-content;
  background-color: #E17400;
  font-size: 35px;
}

.heroHowTo {
  margin-top: 320px;
}

.subtitle {
  margin-top: 30px;
}

@media (max-width: 800px) {
  .heroHeader {
    padding-left: 15px;
    height: 600px;
    margin-top: 80px;
    background-size: cover;
    background-position-x: -360px;
    padding-right: 1000px;
  }

  .heroHeader h1 {
    font-size: 24px;
    margin: 5px;
    padding: 5px;
  }
  
  .heroHeader img {
    max-height: 18px;
    padding-right: 10px;
  }
  
  .heroHeader p {
    margin: 5px;
    padding: 10px;
    font-size: 17px;
  }
}
