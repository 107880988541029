/* Modal Content/Box */
.modalConfirmEndShiftContent {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalConfirmEndShiftTitle {
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 24px;
  margin-left: 10px;
  align-self: center;
}

.modalConfirmEndShiftParagraph {
  color: #333639;
  font-family: Inter;
  font-size: 14px;
  width: 90%;
  margin-left: 10px;
  align-self: center;
}

.modalConfirmEndShiftInput {
  color: #303336;
  margin-top: 7%;
  font-family: Roboto;
  font-size: 16px;
  border-top: white;
  border-right: white;
  border-left: white;
  border-bottom-color: #303336;
  border-width: 1px;
  width: 60%;
  margin-left: 10px;
  position: relative;
}

.modalConfirmEndShiftInput:focus {
  outline-width: 0;
}

.modalConfirmEndShiftInput::placeholder {
  color: #303336;
}

.modalConfirmEndShiftSuccess {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding: 30px 0px 30px 0px;
}

.modalConfirmEndShiftImg {
  width: 88px;
  height: 88px;
}

.modalConfirmEndShiftSuccessText {
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 24px;
  margin: 25px 0px 25px 0px;
}

.modalConfirmEndShiftPasswordNone {
  opacity: 0;
}

.modalConfirmEndShiftPassword {
  color: red;
  font-family: Inter;
  font-size: 14px;
  align-self: center;
}

.modalConfirmEndShiftMainDiv {
  position: relative;
}

.eyeIcon {
  position: absolute;
  top: 20px;
  right: 5px;
  cursor: pointer;
  color: gray;
}

.modalConfirmEndShiftBtn {
  all: unset;
  background-color: white;
  padding: 16px 32px;
  margin: 8px;
  border-radius: 24px;
  opacity: 1;
  cursor: pointer;
  color: white;
  background-color: #e98a15;
  position: relative;
  font-family: Inter;
}

.modalConfirmEndShiftBtn:hover {
  background-color: #ba6f12;
}

.modalConfirmEndShiftBtn:active {
  background-color: #95580c;
}
