/* Modal Content/Box */
.SectorCutContent {
  background-color: #fefefe;
  margin: 3% auto;
  padding: 20px;
  height: 80%;
  border: 1px solid #888;
  width: 430px;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.SectorCutList {
  flex-grow: 1;
  width: 100%;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  overflow-y: scroll;
}

.SectorCutCloseBtn {
  all: unset;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.SectorCutBlob {
  color: #333639;
  font-family: Inter, Semi Bold;
  font-size: 14px;
  max-width: 80%;
  margin-left: 20px;
}

.SectorCutBtn {
  all: unset;
  font: inherit;

  align-self: flex-end;
  justify-self: flex-end;

  background-color: #e98a15;
  border-radius: 24px;

  font-weight: 600;
  color: #fff;
  padding: 10px 20px 12px;

  cursor: pointer;
}

.SectorCutBtn:hover,
.SectorCutBtn:focus-visible {
  background-color: #ba6f12;
}

.SectorCutBtn:disabled {
  background-color: #d1d1d1;
  cursor: inherit;
}

.SectorCutSection {
  margin: 20px 0 12px 20px;
}

.SectorCutName {
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 30px;
  margin: 5px;
}

.SectorCutInputFrame {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-left: -25px;
}

.SectorCutButtonHolder {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin-top: 25px;
}

.SectorCutButtonStepper {
  margin: 2px;

  background-color: white;
  border: 1.7px #63324b solid;
  border-radius: 50%;
  line-height: 16px;
  font-size: 20px;
  font-weight: 600;
  color: #63324b;
}

.SectorCutLabel {
  display: flex;
  flex-direction: column;
  color: #6a6a6a;
  font-size: 12px;
}

.SectorCutInput {
  all: unset;
  font: inherit;

  font-size: 60px;
  color: #333639;
  text-align: center;
  vertical-align: middle;

  width: 100px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: 3px solid #333639;
}

.SectorCutInput:focus-visible {
  color: #e98a15;
  border-color: #e98a15;
}

.SectorCutDecoration {
  color: #333639;
  font-size: 50px;
  font-weight: 500;
  margin-top: 15px;
  margin-left: 5px;
}
