@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinnerLogo {
  width: 10%;
  margin-bottom: 10%;
  animation: infinite spin 2s;
}
