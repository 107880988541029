/* Modal Content/Box */
.modalSelectShiftContent {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 430px;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  transition: 0.2s;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #cecece;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(168, 167, 167);
  opacity: 1;
}

.modalSelectShiftTitle {
  color: #63324b;
  font-size: 30px;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  text-align: left;
  margin: 10px 0px 25px 10px;
}

.modalSelectShiftP {
  font-size: 15px;
  font-family: Inter;
  text-align: left;
  margin: 10px 0px 25px 10px;
}

.modalSelectShiftDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  width: 70%;
}

.modalSelectShiftDivInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  width: 100%;
  height: 50%;
  display: flex;

  padding-left: 15px;
}

.modalSelectShiftInput {
  width: 100%;
  border-radius: 16px;
  border: 1px solid transparent;
  height: 25px;
  box-shadow: 2px 2px 4px #00000029;
  color: #303336;
  font-family: Inter, Regular;
  z-index: 2;
}

.modalSelectShiftInput:focus {
  outline: none;
  border: 1px solid #e98a15;
  border-radius: 16px;
}

.modalSelectShiftLabel {
  z-index: 20;
  position: relative;
  left: 25px;
}

.modalSelectShiftModalBtnDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.modalSelectShiftModalConfirmBtn {
  width: 137px;
  border-radius: 24px;
  padding: 12px 10px 12px 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  color: white;
  border: none;
  background-color: #e98a15;
  margin: 0;
  cursor: pointer;
  border-style: solid;
}

.modalSelectShiftContent .borderless {
  border-color: transparent;
}

.modalSelectShiftModalConfirmBtn:hover {
  background-color: #ba6f12;
}

.modalSelectShiftModalConfirmBtn:disabled {
  background-color: #d1d1d1;
}
