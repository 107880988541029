/* Modal Content/Box */
.modalAddSectorTurnoContent {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modalAddSectorTurnoTitle {
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 24px;
  margin-left: 10px;
  align-self: center;
}

.modalAddSectorTurnoInput {
  color: #303336;
  margin-top: 7%;
  font-family: Roboto;
  font-size: 16px;
  border-top: white;
  border-right: white;
  border-left: white;
  border-bottom-color: #303336;
  border-width: 1px;
  width: 50%;
  margin-left: 10px;
}

.modalAddSectorTurnoInput:focus {
  outline-width: 0;
}

.modalAddSectorTurnoInput::placeholder {
  color: #303336;
}

.modalAddSectorTurnoPasswordNone {
  opacity: 0;
}

.modalAddSectorTurnoPassword {
  color: red;
  font-family: Inter;
  font-size: 14px;
  align-self: center;
}

.modalAddDivOne {
  width: 30%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalAddDivRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
}

.modalAddInputParagraph {
  color: #6a6a6a;
  margin-top: 30px;
  margin-bottom: 0px;
  font-family: Roboto;
  font-size: 12px;
  align-self: flex-start;
}

.modalAddScheduleInput {
  width: 80px;
  font-family: Inter, Semi Bold;
  font-weight: 500;
  /* height: 70px; */
  font-size: 60px;
  border: 2px solid #303336;
  border-top: none;
  border-right: none;
  border-left: none;
  text-align: center;
  color: #e98a15;
  margin-top: 0px;
}

.modalAddScheduleInput::placeholder {
  color: #d1d1d1;
}

.modalAddScheduleInput:focus {
  outline-width: 0;
}

.modalAddPercentage {
  font-family: Inter, Semi Bold;
  font-weight: 500;
  font-size: 60px;
  text-align: center;
  color: #303336;
}

.modalAddTurnosDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.modalAddSectorError {
  color: red;
  font-size: 16px;
  font-family: Inter;
  text-align: left;
  align-self: flex-end;
  margin-left: 10px;
}

.modalAddSectorErrorNone {
  opacity: 0;
  font-size: 16px;
  font-family: Inter;
  text-align: left;
  align-self: flex-end;
  margin-left: 10px;
}

.modalAddSectorParagraph {
  color: #333639;
  font-size: 14px;
  font-family: Inter, Regular;
  margin-left: 10px;
}

.modalAddSectorInputDiv {
  width: 70%;
  margin-left: 10px;
}
