/* Modal Content/Box */
.modalApprovalColaboradorContent {
  background-color: #fefefe;
  margin: 3% auto;
  border: 1px solid #888;
  /* height: 419px; */
  width: 430px;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: hidden;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modalApprovalColaboradorName {
  color: #303336;
  font-size: 20px;
  font-family: Roboto, Bold;
  font-weight: 600;
  text-align: left;
  margin: 10px 0px 25px 10px;
}

.modalApprovalColaboradorTitle {
  color: #63324b;
  font-size: 30px;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  text-align: left;
  margin: 10px 0px 25px 10px;
}

.modalApprovalColaboradorDivInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  position: relative;
  z-index: 1;
  margin-left: 10px;
  padding-bottom: 25px;
}

.modalApprovalColaboradorForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: 0 auto;
  position: relative;
  padding-top: 30px;
  margin-bottom: 30px;
}

.modalApprovalSelectForm {
  width: 100%;
}

.modalApprovalLabel {
  color: #440927;
  font-size: 16px;
  font-family: Inter, Semi bold;
  font-weight: 600;
  align-self: flex-start;
  margin-bottom: 2%;
}

.modalApprovalLabelSector {
  margin-top: 10%;
}

.divLabelApprovalColaboradorModal {
  width: 80%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10%;
  text-align: left;
  font-weight: 300;
  font-family: Helvetica;
  font-size: 14px;
  color: #303336;
  position: relative;
}

.inputApprovalColaboradorTip {
  border-color: white;
  border-top: white;
  border-right: white;
  border-left: white;
  border-bottom-color: #303336;
  border-width: 1px;
  color: black;
  font-size: 16px;
  font-family: Inter, Semi Bold;
  font-weight: 400;
}

.inputApprovalColaboradorTip:focus {
  outline-width: 0;
}

.inputApprovalColaboradorTip::placeholder {
  color: #303336;
}

.inputApprovalColaboradorTip:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #303336;
}

.inputApprovalColaboradorTip::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #303336;
}

.inputApprovalColaboradorTip:focus::-webkit-input-placeholder {
  color: #303336;
}

.inputApprovalColaboradorTip:focus:-moz-placeholder {
  color: #303336;
}

.inputApprovalColaboradorLabelSelected label.Mui-focused {
  color: #e98a15;
}

.inputApprovalColaboradorLabelSelected .MuiInput-underline:after {
  border-color: #e98a15;
}

.ColaboradorCloseBtn {
  all: unset;
  align-self: flex-end;
  position: relative;
  color: #707070;
  left: -10px;
  bottom: -10px;
}

.employeeProfile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 6%;
}

.employeeProfile .employeeData {
  max-width: 80%;
  min-width: 60%;
  display: flex;
  flex-direction: column;
}

.employeeProfile .colaboradorName {
  color: #440927;
  line-break: auto;
  margin: 15px 15px 1px 15px;
  font-weight: 600;
  font-family: Inter;
  font-size: 24px;
}

.colaboradorDoc {
  color: #440927;
  font-family: Inter, Regular;
  font-weight: 400;
  font-size: 16px;
  margin: 5px 15px 15px 15px;
}

.employeeData .MuiDivider-flexItem {
  height: 2px;
}

.modalApprovalColaboradorDivInput .borderless {
  border-color: transparent;
}

.modalApprovalColaboradoresSubtitleDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.modalApprovalColaboradoresAliasText {
  height: 20px;
  color: #440927;
  font-weight: 600;
  font-family: Inter;
  font-size: 18px;
  margin: 0 15px 0 15px;
}

.modalApprovalColaboradoresAliasText:first-of-type {
  margin-top: 10px;
}
