/* Modal Content/Box */
.modalSuccessContent {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalSuccessTitle {
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 24px;
  margin-left: 10px;
  align-self: center;
}

.modalSuccessParagraph {
  color: #303336;
  font-family: Inter;
  font-size: 14px;
  width: 90%;
  margin-left: 10px;
  align-self: center;
  text-align: center;
}

.modalSuccessBtn {
  display: flex;
  flex-direction: row;
  margin-top: 8%;
  width: 100%;
  margin-left: -10px;
  justify-content: flex-end;
}

.modalSuccess {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding: 30px 0px 30px 0px;
}

.modalSuccessImg {
  width: 88px;
  height: 88px;
}

.modalSuccessText {
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 24px;
  margin: 25px 0px 25px 0px;
  text-align: center;
}

.modalSuccessPasswordNone {
  opacity: 0;
}

.modalSuccessConfirm {
  width: 142px;
  border-radius: 24px;
  padding: 12px 10px 12px 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: #e98a15;
  color: white;
  margin: 0;
  border-width: 0;
  cursor: pointer;
}

.modalSuccessConfirm:hover {
  opacity: 1;
  background: #ba6f12;
}

.modalSuccessConfirmSectors {
  width: 200px;
  border-radius: 24px;
  padding: 12px 10px 12px 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: #e98a15;
  color: white;
  margin: 0;
  border-width: 0;
  cursor: pointer;
}

.modalSuccessConfirmSectors:hover {
  opacity: 1;
  background: #ba6f12;
}

.modalSuccessSubtitle {
  position: relative;
  margin-top: 0;
  bottom: 15px;
  color: #333639;
  font-family: Inter, Regular;
}
