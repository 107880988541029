/* Modal Content/Box */
.modalSectoresContent {
  background-color: #fefefe;
  margin: 3% auto;
  padding: 20px;
  max-height: 60%;
  border: 1px solid #888;
  width: 430px;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  transition: 0.2s;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #cecece;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(168, 167, 167);
  opacity: 1;
}

.modalSectoresCloseBtn {
  all: unset;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.modalSectoresSectorName {
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 24px;
  padding-left: 5px;
}

.modalSectoresSpacer {
  content: '';
  margin: 25px 0 0 0;
}

.modalSectoresP {
  color: #333639;
  font-family: Inter, Semi Bold;
  font-size: 14px;
  max-width: 70%;
  padding-left: 5px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.modalSectoresInputDiv {
  width: 70%;
}

.modalSectoresDeleteBtnDiv {
  margin-top: 10%;
  margin-bottom: 0;
  padding-bottom: 0;
}

.modalSectoresPDelete {
  color: #333639;
  font-family: Inter, Semi Bold;
  font-size: 14px;
  max-width: 70%;
  padding-left: 5px;
}
