/* Modal Content/Box */
.modalPreconfigContent {
  background-color: #fefefe;
  margin: 3% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  transition: 0.2s;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #cecece;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(168, 167, 167);
  opacity: 1;
}

.modalPreconfigTitle {
  color: #63324b;
  font-size: 30px;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  text-align: left;
  margin: 10px 0px 0px 10px;
}

.modalPreconfigParagraph {
  color: #303336;
  font-size: 14px;
  width: 80%;
  font-family: Inter;
  text-align: left;
  margin: 10px 0px 25px 10px;
}

.modalPreconfigButtonDiv {
  display: flex;
  justify-content: flex-end;
}

.modalPreconfigButton {
  width: 142px;
  border-radius: 24px;
  padding: 12px 10px 12px 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: #e98a15;
  color: white;
  margin: 0;
  border-width: 0;
  cursor: pointer;
}

.modalPreconfigButton:hover {
  opacity: 1;
  background: #ba6f12;
}
