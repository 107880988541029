.datosConfigCardContainerDiv {
  width: 82%;
  position: relative;
  left: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.datosConfigHeaderDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding-top: 60px;
}

.datosConfigGoBackDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #6a6a6a;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.datosConfigGoBackParagraph {
  padding-left: 6px;
}

.datosConfigTitle {
  color: #303336;
  font-family: Inter, Semi Bold;
  font-size: 30px;
}

.datosConfigParagraph {
  color: #303336;
  font-family: Inter;
  font-size: 16px;
}

.datosConfigCenterDiv {
  width: 91%;
  margin-top: 0px;
}

.datosConfigDivOne {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.datosConfigImg {
  width: 75px;
  height: 75px;
  border-radius: 50px;
  background-color: rgb(255, 242, 166);
}

.datosConfigDivTwo {
  margin-left: 10px;
}

.datosConfigDivTwoTitle {
  color: #303336;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 5px;
}

.datosConfigDivTwoParagraph {
  font-family: Inter;
  font-size: 16px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
}

.datosConfigDivThreeParagraph {
  color: #303336;
  font-size: 16px;
  font-family: Inter;
}

.datosConfigDivHoverStyle {
  background-color: #d1d1d1;
  /* cursor: pointer; */
  width: 75px;
  height: 75px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datosConfigAddPhotoIcon {
  width: 32px;
}

.datosConfigContactDiv {
  justify-self: flex-start;
  align-self: flex-start;
  margin-left: 4%;
  color: #303336;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5%;
  width: 100%;
}

.datosConfigContactTitle {
  margin-bottom: 0;
  padding-bottom: 0;
}

.datosConfigContactIconDiv {
  display: flex;
  flex-direction: row;
  margin-top: 0;
}

.datosConfigContactIcon {
  margin-right: 1%;
}

.datosConfigDivExtraData {
  display: flex;
  flex-direction: row;
}

.datosConfigDivExtraDataText {
  color: #303336;
  font-size: 16px;
  font-family: Inter;
  font-weight: bold;
  margin-right: 5px;
}

.datosConfigQRSecondaryDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.datosConfigQRText {
  width: 536px;
  color: #303336;
  font-family: Inter;
  font-size: 16px;
}

.datosConfigQRBtn {
  all: unset;
  background-color: white;
  padding: 0px 15px 0px 15px;
  margin-right: 120px;
  border-radius: 24px;
  opacity: 1;
  cursor: pointer;
  color: #e98a15;
  border: 1px solid #e98a15;
  font-family: Inter, Semi bold;
  height: 50px;
  width: 200px;
  text-align: center;
}

.datosConfigQRBtn:hover {
  color: #ba6f12;
  border-color: #ba6f12;
}

.datosConfigQRBtn:active {
  color: #95580c;
  border-color: #95580c;
}
