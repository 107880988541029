.modalTerms {
  display: block;
  position: fixed;
  z-index: 22;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  overflow: hide;

  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalTermsContent {
  background-color: #fefefe;
  margin: 3% auto;
  padding: 20px;
  padding-bottom: 10px;
  border: 1px solid #888;
  width: 35%;
  height: 85vh;
  opacity: 0;
  animation: fadeInModal 0.2s linear forwards;
  box-shadow: 0px 3px 6px #00000029;

  overflow-x: scroll;
}

@media (max-width: 800px) {
  .modalTermsContent {
    width: 80%;
  }
  
}
