.endShiftMainDiv {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding-top: 100px; */
}

.mainContentEndShiftDiv {
  min-height: 100vh;
  width: 100%;
  position: relative;
  margin-left: 18%;
  margin-top: 90px;
  padding-top: 30px;
  background-color: #f5f5f5;
}

.endShiftContainer {
  width: 82%;
  position: relative;
  margin-left: 2%;
  /* left: 18%; */
  /* display: flex;
    justify-content: center;
    align-items: center; */
  overflow: hidden;
}

.endShiftGoBackDiv {
  /* margin-left: 40px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #6a6a6a;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.endShiftGoBackParagraph {
  padding-left: 6px;
}

.endShiftHeaderDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  /* flex-direction: column; */
}

.endShiftTitleDiv {
  width: 75%;
  margin-top: 0;
  margin-left: 10px;
  color: #303336;
}

.endShiftTitle {
  font-family: Inter, Semi Bold;
  font-size: 30px;
}

.endShiftParagraph {
  font-family: Inter;
  font-size: 16px;
  width: 85%;
}

.endShiftDivInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 20px 0px 20px 0px;
}

.endShiftInput {
  width: 40%;
  border-radius: 16px;
  border: 1px solid transparent;
  height: 25px;
  position: relative;
  color: #303336;
  font-family: Inter, Regular;
  padding-left: 30px;
  z-index: 2;
  box-shadow: 2px 2px 4px #00000029;
}

.endShiftInput:focus {
  outline: none;
}

.endShiftLabel {
  z-index: 20;
  position: absolute;
  left: 10px;
}

.endShiftBtn {
  width: 160px;
  height: 40px;
  border-radius: 24px;
  padding: 12px 10px 12px 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: #e98a15;
  color: white;
  margin: 0;
  border-width: 0;
  cursor: pointer;
  align-self: center;
}

.endShiftColaboradoresDiv {
  display: flex;
}

.endShiftColaboradoresData {
  width: 200px;
  margin: 40px;
}

.endShiftSectorTitle {
  font-family: Inter, Semi Bold;
  color: #333639;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.endShiftSectorPercentage {
  font-family: Inter;
  color: #333639;
  font-size: 14px;
  margin-top: 0;
}

.endShiftSectorEdit {
  color: #e98a15;
  font-family: Inter, Semi Bold;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.endShiftColaboradorItem {
  color: #440927;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  position: relative;
}

.endShiftColaboradorNameDiv {
  display: flex;
  flex-direction: column;
  width: 120px;
  margin-left: 10px;
}

.endShiftColaboradorNameText {
  margin: 0;
  font-family: Inter;
}

.endShiftColaboradorItemNickname {
  font-weight: bold;
  font-size: 14px;
}
