.insertNewPasswordDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-left: 3%;
  margin-top: 36px;
}

.inputInsertNewPassword {
  border-top: white;
  border-right: white;
  border-left: white;
  border-bottom-color: #303336;
  border-width: 1px;
  font-family: Roboto, Regular;
  color: #303336;
  font-size: 16px;
}

.inputInsertNewPassword:first-of-type {
  margin-bottom: 40px;
}

.inputInsertNewPassword:focus {
  outline-width: 0;
  color: #e98a15;
  border-bottom-color: #e98a15;
}

.insertPasswordErrorDiv {
  margin-left: 16px;
  margin-top: -25px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  color: #c51111;
  opacity: 1;
}

.insertPasswordErrorDivNone {
  margin-left: 16px;
  margin-top: -25px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  opacity: 0;
}

.insertPasswordErrorText {
  margin-left: 2px;
  color: #c51111;
  font-family: Roboto, Regular;
  font-weight: 450;
  font-size: 12px;
}

.divLabelInsertNewPassword {
  width: 60%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 28px;
  margin-left: 3%;
  text-align: left;
  font-weight: 300;
  font-family: Roboto, Regular;
  font-size: 14px;
  color: #6e6e6e;
  position: relative;
}

.eyeIcon {
  position: absolute;
  top: 20px;
  right: 5px;
  cursor: pointer;
}
