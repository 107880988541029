.QRMainDiv {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-evenly;
  width: 100%;
  background-color: white;
}

.QRHeaderDiv {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.QRMainTitle {
  font-size: 22px;
  color: #440927;
  padding-bottom: 0;
  margin-bottom: 0;
}

.QRMainSubtitle {
  width: 80%;
  font-weight: 500;
}

.QRSecondaryDiv {
  display: flex;
  justify-content: center;
  width: 90%;
  align-self: center;
  border-color: #e8e8e8;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
}

.QRCardDiv {
  width: 90%;
  align-self: center;
  height: fit-content;
}

.QRCardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
}

.QRSecondaryLogo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.QRCommerceTitle {
  font-size: 18px;
  color: #440927;
  font-weight: 500;
}

.QRCardContent {
  border-radius: 20px;
  background-color: #f7f7f7;
  box-shadow: 0px 3px 6px #00000029;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.QRPlaceholderLogo {
  width: 80px;
  height: 78px;
  margin-right: 5px;
}

.QRCardTextPrimary {
  color: #440927;
  font-weight: 500;
  padding-bottom: 0;
  margin-bottom: 0;
}

.QRCardTextSecondary {
  width: 95%;
  color: #440927;
  font-size: 14px;
  padding-top: 0;
  margin-top: 0;
}

.QRAddCardTextSecondary {
  width: 100%;
  color: #440927;
  font-size: 14px;
  padding-top: 0;
  margin-top: 0;
}

.QRFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.QRConfirm {
  width: 180px;
  border: none;
  background-color: #440927;
  color: white;
  border-radius: 10px;
  height: 40px;
  font-size: 16px;
  margin-bottom: 15px;
  cursor: pointer;
}

.QRCancel {
  width: 180px;
  font-size: 16px;
  border: none;
  background-color: white;
  color: #440927;
  border-radius: 10px;
  height: 40px;
  margin-bottom: 15px;
  cursor: pointer;
}

.QRPrimaryLogo {
  width: 150px;
}
