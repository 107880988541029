.mainLoginView {
  background-color: #f5f5f5;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
/* 
.overTop {
    background-color: rgba(228, 228, 228, 1);
    height: 250px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
} */
