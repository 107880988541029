.profileNameContainer {
  width: 75px;
  height: 75px;
  background-color: #440927;
  border-radius: 50px;
  color: #ffffff;
  font-size: 32px;
  font-family: Inter;
  display: flex;
  justify-content: center;
  align-items: center;
}
