.colaboradoresDiv {
  width: 100%;
  position: relative;
  background-color: #f5f5f5;
}

.colaboradoresCardContainerDiv {
  width: 82%;
  position: relative;
  left: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.colaboradoresCardContent {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
}

.colaboradoresHeader {
  align-self: center;
  width: 100%;
  margin-left: 10%;
  padding-top: 120px;
}

.colaboradoresTitle {
  color: #303336;
  font-family: Inter, Semi Bold;
  font-size: 30px;
  /* font-weight: 500; */
}

.colaboradoresParagraph {
  color: #303336;
  font-family: Inter, Regular;
  font-size: 16px;
}

.colaboradoresDivInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
  position: relative;
  z-index: 1;
  margin: 20px 0px 20px 0px;
}

.colaboradoresLabel {
  z-index: 20;
  position: absolute;
  left: 10px;
}

.colaboradoresInput {
  width: 100%;
  border-radius: 16px;
  box-shadow: 2px 2px 4px #00000029;
  border: 1px solid transparent;
  height: 30px;
  position: relative;
  color: #303336;
  font-family: Inter, Regular;
  padding-left: 30px;
  z-index: 2;
}

.colaboradoresInput:focus {
  outline: none;
  border: 1px solid #e98a15;
  border-radius: 16px;
}

.nuevoColaboradorBtn {
  width: 200px;
  height: 40px;
  border-radius: 24px;
  padding: 12px 25px 12px 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: white;
  border-style: solid;
  border-color: #e98a15;
  border-width: 1px;
  color: #e98a15;
  margin: 0;
  cursor: pointer;
  align-self: center;
}

.nuevoColaboradorBtn:hover {
  background-color: #d1d1d133;
}

.colaboradoresTableDiv {
  width: 90%;
  margin-top: 30px;
}

.mainContentColaboradoresDiv {
  min-height: 100vh;
  width: 100%;
}

.colaboradoresSearchDiv {
  width: 100%;
  border-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  z-index: 10;
  top: 40px;
  padding-top: 40px;
  background-color: white;
  box-shadow: 2px 2px 4px #00000029;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: Inter, Regular;
  margin-bottom: 20px;
}

.colaboradoresSearchDivNone {
  display: none;
}

.colaboradoresSearchParagraph {
  margin-left: 10px;
  margin-top: 20px;
  color: #6a6a6a;
  font-size: 16px;
  width: 90%;
}

.colaboradoresSearchParagraph:hover {
  background-color: #b4b3b329;
}
