.colaboradorItemDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  position: relative;
}

.colaboradorItemData {
  display: flex;
}

.colaboradorItemImg {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  align-self: center;
}

.colaboradorItemImgInactive {
  opacity: 0.5;
  width: 70px;
  height: 70px;
  border-radius: 50px;
  align-self: center;
}

.colaboradorItemText {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.colaboradorItemTextInactive {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: #d1d1d1;
}

.colaboradorItemFirst {
  margin: 0;
  width: 120px;
  font-family: Inter;
  color: #440927;
  font-size: 16px;
}

.colaboradorItemFirstInactive {
  margin: 0;
  width: 120px;
  font-family: Inter;
  color: #d1d1d1;
  font-size: 16px;
}

.colaboradorItemSecond {
  margin: 0.5% 0 0 0;
  font-family: Inter;
  font-weight: 600;
  color: #440927;
  font-size: 14px;
}

.colaboradorItemSecondInactive {
  margin: 0.5% 0 0 0;
  font-family: Inter;
  font-weight: 600;
  color: #d1d1d1;
  font-size: 14px;
}

.colaboradorItemIcon {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  align-self: center;
  margin-left: 50px;
  cursor: pointer;
}

.colaboradorInactive {
  opacity: 0.5;
  color: #d1d1d1;
}
