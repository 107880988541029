.landingFooter {
  min-width: 100%;
  position: relative;
}

.landingFooterDiv {
  padding: 20px 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.landingStoreIcons {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.landingLeftSide {
  width: 100%;
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.landingFooter a {
  display: block;
}

.storeIconIos {
  width: auto;
  height: 50px;
  margin-right: 25px;
}

.storeIconAndroid {
  width: auto;
  height: 60px;
  margin-right: 25px;
}

@media (max-width: 900px) {
  .landingFooter {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

/* Flex container for badges */
.storeIconsFlex {
  display: flex;
  align-items: center; /* Center-align items vertically */
  justify-content: space-around; /* Space out the badges if desired */
}

/* Common styles for badges, with adjustments for mobile screens */
.storeBadge {
  width: 150px; /* Adjust the width as you see fit */
  height: auto; /* Keeps the aspect ratio intact */
  border-radius: 13px; /* Apply border-radius if desired */
  padding: 10px;
}

@media (max-width: 600px) {
  .landingText a {
    font-size: 14px;
  }
  .landingText {
    max-width: 30%;
  }

  .landingFooterDiv {
    padding-top: 10px;
  }

  .storeBadge {
    width: 120px; /* Smaller width for mobile devices */
  }
}
