.navButtonPrimary,
.navButtonSecondary {
  all: unset;
  background-color: white;

  padding: 16px 32px;
  margin: 8px;
  border-radius: 24px;
  opacity: 1;
  cursor: pointer;

  font-family: Inter, Semi Bold;
}

.navButtonPrimary {
  color: white;
  background-color: #e98a15;
}

.navButtonPrimary:hover {
  background-color: #ba6f12;
}

.navButtonPrimary:active {
  background-color: #95580c;
}

.navButtonPrimary:disabled {
  background-color: #d1d1d1;
  cursor: not-allowed;
}

.navButtonSecondary {
  color: #e98a15;
  border: 1px solid #e98a15;
  cursor: pointer;
}

.navButtonSecondary:hover {
  color: #ba6f12;
  border-color: #ba6f12;
}

.navButtonSecondary:active {
  color: #95580c;
  border-color: #95580c;
}

.navButtonSecondary:disabled {
  background-color: white;
  color: #cfcfcf;
  border-color: #cfcfcf;
  cursor: not-allowed;
}
