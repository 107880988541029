.registerModal {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.mapModal {
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.registerModal img {
  width: 125px;
  height: auto;
}

.registerModal h1,
.mapModal h1 {
  font-size: 28px;
  margin: 25px;
  color: #63324b;
  text-align: center;
}

.registerFormIncorrectEmail {
  background-color: #e98a15;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  width: 60%;
}

.registerModal p {
  text-align: center;
  margin: 0 35px;
}

.registerModal form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}

.registerModal form .formFlexBox {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* flex-wrap: wrap; */
  width: 800px;
  overflow-x: hidden;
}

.formLarge,
.formMedium,
.formSmall {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
}

.formFlexBox .formLarge {
  width: 325px;
}

.formFlexBox .formMedium {
  width: 175px;
}

.formFlexBox .formSmall {
  width: 125px;
}

.registerModal .goBack {
  all: unset;
  align-self: flex-start;
  cursor: pointer;
  font-family: inherit;
  color: gray;
}

.formRow {
  width: 100%;
}

.formRow .column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.registerModal form .navButtonPrimary {
  align-self: flex-end;
  max-width: 200px;
}

.tosTrigger {
  cursor: pointer;
  font-weight: bold;
  color: #e98a15;
}

#map {
  height: 500px;
  width: 500px;
}

.formFlexBoxOne {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.modalErrorDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  position: relative;
}

.modalErrorTitle {
  color: #e98a15;
  margin-bottom: 20px;
  padding: 0px 30px 0px 30px;
}

.modalErrorText {
  font-weight: 400;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 0;
}

.modalErrorImg {
  margin-top: 20px;
  width: 200px;
}

.modalErrorIcon {
  color: gray;
  position: absolute;
  top: 15px;
  right: 15px;
}

.dialog {
  width: 100%;
}

@media (max-width: 900px) {
  .column .formRow {
    flex-direction: row;
  }
  label {
    text-align: justify;
  }

  .registerModal form .formFlexBox {
    width: 600px;
    overflow-x: hidden;
  }
  .formLarge,
  .formMedium,
  .formSmall {
    margin-left: 25px;
    margin-right: 25px;
  }
  .formFlexBox .formLarge {
    width: 275px;
  }
  .formFlexBox .formMedium {
    width: 150px;
  }
  .formFlexBox .formSmall {
    width: 150px;
  }

  .formFlexBoxOne {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media (max-width: 1000px) {
  .registerModal form .formFlexBox {
    width: auto;
    overflow-x: hidden;
    justify-content: center;
  }

  .formFlexBox .formLarge {
    margin: 0px;
    width: 100%;
  }
  .formFlexBox .formMedium {
    margin: 0px;
    width: 100%;
  }
  .formFlexBox .formSmall {
    margin: 0px;
    width: 100%;
  }

  .modalErrorTitle {
    padding: 0px;
    font-size: 24px;
  }
}
