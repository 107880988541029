.topNav {
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  background-color: white;
}

.topNavLanding {
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 22;
  background-color: transparent;
}

.tipieLogoNav {
  margin-left: 2%;
  width: 100px;
}
