.faqCard {
  width: 90%;
  margin-left: 5%;
  margin-right: 1%;
}

.sectionIcon {
  width: 32px;
  height: 32px;
}

.nextToIcon {
  flex-grow: 1;
  padding-top: 0;
  margin-top: 0;
}

.faqText {
  font-size: 24px !important;
  margin: 10px 0px 0px 0px !important;
}

.faqCardTitle {
  padding-top: 20px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.faqMainTitle {
  color: #5f0922;
  padding-left: 15px;
  font-size: 24px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.faqPaymentDiv {
  width: 45%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.faqPaymentDivTwo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.faqPaymentImg {
  /* position: absolute; */
  width: 110px;
  height: 110px;
  align-self: flex-end;
}

.faqPaymentMainTitle {
  color: #5f0922;
  font-size: 24px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.faqPaymentParagraph {
  padding-top: 0;
  margin-top: 0;
  color: #6a6a6a;
  font-size: 20px;
}

.faqPaymentSubtitle {
  width: 100%;
}

.whatIsSubtitle {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  color: #666666;
}

.faqFirstQuestion {
  margin-left: 7%;
}

@media (max-width: 800px) {
  .faq {
    padding: 0;
    width: 100%;
    margin-left: 25px;
  }

  .faqFirstQuestion {
    font-size: 24px;
    margin-bottom: 0px;
    margin-left: 20px;
  }

  .faqQuestion {
    margin-top: 20px !important;
    margin-bottom: 5px !important;
  }

  .faqCard {
    width: auto;
    margin: 5px 10px 0px 10px;
  }

  .faqCard:has(.corner) {
    padding-left: 150px;
    max-height: 250px;
  }

  .faqCard:has(.altCorner) {
    max-height: 25right0px;
  }

  .faqMainTitle {
    padding-left: 0;
  }

  .faqPaymentParagraph {
    font-size: 15px;
  }

  .faqPaymentSubtitle {
    font-size: 16px;
  }

  .faqPaymentMainTitle {
    font-size: 15px;
  }

  .whatIsSubtitle {
    font-size: 19px;
  }

  .whatIsBold,
  .whatIsBoldTitle,
  .available,
  .faqText {
    font-size: 17px !important;
  }
}