.terms {
}

.terms h1 {
  color: #440927;
  font-size: 32px;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
  text-align: center;
}

.terms h2 {
  color: #440927;
  font-weight: bold;
  font-size: 14px;
}

.terms p {
  font-size: 11px;
  text-align: justify;
}

.terms a {
  cursor: pointer;
  color: #e98a15;
  font-weight: 'bold';
}
