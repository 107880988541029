.homeDiv {
  width: 100%;
  background-color: #f5f5f5;
}

body {
  background-color: #f5f5f5;
}

.mainContentHomeDiv {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 15px;
  background-color: #f5f5f5;
  position: relative;
  overflow-y: hidden;
}

.topNavBarChildrenDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20%;
  cursor: pointer;
}

.topNavBarChildrenTitle {
  font-family: Inter, Regular;
  font-size: 16px;
  font-weight: 400;
  padding-right: 10px;
  padding-left: 10px;
}

.homeCardContainerDiv {
  overflow-y: hidden;
  width: 82%;
  height: 100%;
  position: relative;
  left: 18%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
  align-items: center;
  background-color: #f5f5f5;
  overflow-x: scroll;
  padding-bottom: 50px;
}

.homeGrid {
  height: 65%;
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'divOne divOne divTwo'
    'divThree divFour divFive';
  align-items: center;
  justify-content: center;
  column-gap: 2%;
}

.homeCardContainerOne {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.homeCardOne {
  width: 100%;
  height: 185px;
  /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
  grid-area: divOne;
}

.homeCardOneDiv {
  width: 100%;
  background: white;
  align-self: center;
  height: 100%;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
}

.homeCardOneParagraph {
  color: #303336;
  font-family: Inter, Regular;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px 0px 0px 20px;
}

.homeCardOneTitle {
  color: #63324b;
  font-size: 24px;
  font-family: Inter, Semi Bold;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
  padding: 10px 0px 0px 20px;
}

.homeCardOneTotal {
  text-align: left;
  font-family: Inter, Bold;
  color: #63324b;
  font-size: 60px;
  margin-top: 0;
  padding: 20px 0px 0px 20px;
  position: relative;
}

.homeCardOneSpan {
  color: #63324b;
  text-align: left;
  font-family: Inter, Bold;
  font-size: 30px;
  position: relative;
  top: -25px;
}

.homeCardOneButton {
  width: 190px;
  padding: 12px 30px 12px 30px;
  font-weight: 600;
  margin-right: 20px;
  background: #e98a15;
  font-family: Inter, Semi Bold;
  font-size: 16px;
  border: none;
  color: white;
  border-radius: 24px;
  cursor: pointer;
  margin-top: 20px;
  transition: 0.2s;
}

.homeCardOneButtonDisabled {
  width: 190px;
  padding: 12px 30px 12px 30px;
  font-weight: 600;
  margin-right: 20px;
  background: #b4b4b4;
  font-family: Inter, Semi Bold;
  font-size: 16px;
  border: none;
  color: white;
  border-radius: 24px;
  transition: 0.2s;
  margin-top: 20px;
}

.homeCardOneButton:hover {
  opacity: 1;
  background: #ba6f12;
}

.homeCardTwo {
  width: 100%;
  height: 185px;
  display: flex;
  grid-area: divTwo;
}

.homeCardContainerTwo {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}

.homeCardTwoDiv {
  width: 100%;
  margin-right: 0%;
  background: white;
  align-self: center;
  height: 100%;
  position: relative;
  /* padding-left: 20px; */
  box-shadow: 0px 3px 6px #00000029;
}

.homeCardTwoTitle {
  font-family: Inter, Semi Bold;
  font-size: 30px;
  color: #63324b;
  text-align: left;
  padding-left: 20px;
  margin-bottom: 0px;
}

.homeCardTwoParagraph {
  color: #303336;
  font-family: Inter, Regular;
  font-size: 16px;
  text-align: left;
  margin-top: 5px;
  padding-left: 20px;
}

.homeCardTwoButton {
  width: 160px;
  color: #e98a15;
  background: white;
  font-family: Inter, Semi Bold;
  font-size: 16px;
  border-style: solid;
  border-width: 1px;
  font-weight: 600;
  padding: 12px 30px 12px 30px;
  border-color: #e98a15;
  border-radius: 24px;
  cursor: pointer;
  transition: 0.2s;
  align-self: flex-end;
  position: relative;
  margin-right: 16px;
  margin-bottom: 20px;
}

.homeCardTwoButtonDisabled {
  width: 160px;
  background-color: #e98a15;
  font-family: Inter, Semi Bold;
  font-size: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #e98a15;
  font-weight: 600;
  padding: 12px 30px 12px 30px;
  color: white;
  border-radius: 24px;
  cursor: pointer;
  transition: 0.2s;
  align-self: flex-end;
  position: relative;
  margin-bottom: 20px;
}

.homeCardTwoButton:hover {
  opacity: 1;
  color: #e98a15;
  background-color: #d1d1d133;
}

.homeCardThree {
  width: 100%;
  height: 180px;
  position: relative;
  grid-area: divThree;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.homeCardFour {
  background: white;
  width: 100%;
  height: 180px;
  position: relative;
  grid-area: divFour;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.homeCardFive {
  width: 100%;
  height: 180px;
  position: relative;
  grid-area: divFive;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.homeSmallerDiv {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0px 3px 6px #00000029;
}

.homeSmallerTitle {
  width: 80%;
  height: 100%;
  min-height: 3em;
  font-family: Inter, Semi Bold;
  color: #63324b;
  font-size: 24px;
  margin-top: 0;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.homeSmallerTitle4 {
  width: 80%;
  height: 100%;
  min-height: 3em;
  font-family: Inter, Semi Bold;
  color: #63324b;
  font-size: 22px;
  margin-top: 0;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.homePendingTitle {
  max-width: 180px;
  min-height: 2em;
  font-family: Inter, Semi Bold;
  color: #63324b;
  font-size: 24px;
  margin-top: 0;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 0;
  margin-bottom: 0;
  vertical-align: middle;
  flex-grow: 1;
}

.homePendingNumber {
  font-family: Inter, Bold;
  font-size: 50px;
  width: 100%;
  color: #63324b;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 0;
  vertical-align: middle;
  text-align: center;
}

.homeSmallerNumber {
  font-family: Inter, Bold;
  font-size: 60px;
  color: #63324b;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 0;
  position: relative;
  bottom: 30px;
  padding-left: 20px;
}

.homeSmallerNumber4 {
  font-family: Inter, Bold;
  font-size: 60px;
  color: #63324b;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 0;
  position: relative;
  bottom: 25px;
  padding-left: 20px;
}

.homeSmallerSeeMore {
  font-size: 16px;
  color: #e98a15;
  font-family: Inter, Bold;
  font-weight: 600;
  cursor: pointer;
  align-self: flex-end;
  position: relative;
  bottom: 25px;
  right: 20px;
}

.homeSmallerSeeMore4 {
  font-size: 16px;
  color: #e98a15;
  font-family: Inter, Bold;
  font-weight: 600;
  cursor: pointer;
  align-self: flex-end;
  position: relative;
  bottom: 25px;
}

.homeSmallerSeeMore:hover {
  color: #ba6f12;
}
.homeNoActiveTitle {
  color: #63324b;
  font-size: 18px;
  font-family: Inter, Semi Bold;
  text-align: center;
}

.homeEndShiftDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  height: 65%;
}
