/* Modal Content/Box */
.modalDisabledColabContent {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  height: 30%;
  border: 1px solid #888;
  width: 430px;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  transition: 0.2s;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #cecece;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(168, 167, 167);
  opacity: 1;
}

.modalDisabledColabTitle {
  color: #63324b;
  font-size: 30px;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  text-align: left;
  margin: 10px 0px 0px 10px;
}

.modalDisabledColabParagraph {
  color: #303336;
  font-size: 16px;
  width: 90%;
  font-family: Inter;
  text-align: center;
  margin: 20px 0px 25px 10px;
}

.modalDisabledColabBtnDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modalDisabledColabBtnCancelar {
  width: 134px;
  border-radius: 24px;
  padding: 12px 10px 12px 10px;
  font-size: 14px;
  font-family: Inter, Semi Bold;
  background-color: white;
  border-color: #e98a15;
  border-width: 1px;
  color: #e98a15;
  margin: 0;
  position: relative;
  right: 10px;
  cursor: pointer;
  border-style: solid;
  font-weight: 500;
}
