.approvalDiv {
  width: 100%;
  position: relative;
  background-color: #f5f5f5;
}

.mainContentApprovalDiv {
  min-height: 100vh;
  width: 100%;
}

.innerApprovalDiv {
  padding-top: 90px;
  padding-left: 20%;
}

.approvalMainCard {
  background-color: white;
  padding: 5px 40px 5px 40px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 17px;
  width: fit-content;
}

.approvalCardNameDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.approvalCardNameDiv h4 {
  margin-left: 16px;
  margin-bottom: 0;
  margin-top: 0;
}

.approvalCardDoc {
  color: #5f0922;
  font-family: Inter, Regular;
  font-weight: 400;
  margin-top: 8%;
}

.approvalCardFullNameDiv {
  margin-top: 5%;
}

.approvalCardFullName {
  color: #5f0922;
  font-family: Inter, Regular;
  font-weight: 500;
  font-size: 20px;
}

.approvalCardNameDiv p {
  margin-left: 16px;
}

.approvalCardButtonDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 10px;
}

.approvalBtn,
.approvalBtnSmall {
  all: unset;
  background-color: white;
  color: #e98a15;
  width: 150px;
  height: 40px;
  border-radius: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.approvalBtn:disabled,
.approvalBtnSmall:disabled {
  color: #cfcfcf;
}

.approvalBtn:hover,
.approvalBtnSmall:hover {
  color: #cfcfcf;
  border-color: #cfcfcf;
}

.approvalBtn {
  border: solid 1px #e98a15;
  font-family: Inter, Semi bold;
}

.approvalBtnSmall {
  font-family: Inter, Semi bold;
}

.approvalMainTitle {
  padding-top: 40px;
  padding-bottom: 0;
  margin-bottom: 0;
  font-family: Inter, Semi bold;
  font-size: 30px;
  color: #303336;
  /* font-weight: 500; */
}

.approvalSubtitle {
  color: #303336;
  font-family: Inter, Regular;
  font-size: 16px;
}

.approvalNoPending {
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  width: fit-content;
  color: #303336;
  font-size: 20px;
  padding: 25px;
}
