h2,
h3 {
  color: #5f0922;
}

h4,
h5 {
  color: #6a6a6a;
}

h2 {
  font-size: 32px;
  margin-bottom: 10px;
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 28px;
}

.icon {
  width: 30px;
  position: relative;
}

.side {
  position: relative;
  width: 70px;
  height: 100%;
  margin-top: 40px;
  margin-right: 20px;
}

.corner {
  position: absolute;
  right: 0;
  bottom: -90px;
  width: 100px;
}

.altCorner {
  position: absolute;
  top: 30%;
  right: 105%;
  width: 100px;
}

h5,
b {
  font-size: 20px;
  color: #666666;
  font-weight: bold;
}

.Card p {
  width: 100%;
  font-size: 28px;
  color: #666666;
  margin: 5px;
}

.Card ol {
  font-size: 20px;
  color: #666666;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.cardSmallTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.cardSmallTitleP {
  color: #5f0922;
  margin: 0;
  padding-left: 10px;
  font-size: 32px;
}

.cardSmallTitleFaq {
  color: #5f0922;
  font-size: 24px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.cardSmallTitleHowTo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 0;
}

.stepsContainer {
  margin-left: 5%;
  margin-right: 1%;
}

.Card {
  padding-right: 20px;
}

@media (max-width: 800px) {

  .Card h2,
  .Card h5 {
    margin: 0;
    font-size: 24px;
    text-align: left;
  }

  .Card h3 {
    font-size: 25px;
    text-align: left;
  }

  .Card h4 {
    font-size: 20px;
    text-align: left;
  }

  .Card p {
    font-size: 17px;
    text-align: left;
  }

  .icon {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 25px;
    top: 25px;
  }

  .side {
    position: relative;
    height: 100%;
    margin-top: 20px;
    padding-left: 20px;
    width: 60px;
  }

  .corner {
    width: 100px;
    top: 10px;
    left: 0px;
  }

  .altCorner {
    right: -80px;
    width: 120px;
  }

  .whatIs .firstP {
    font-size: 17px;
  }

  .whatIs {
    padding-right: 3%;
  }
}