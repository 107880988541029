.sideBarDiv {
  width: 18%;
  background: #ffffff;
  min-height: 100vh;
  max-height: 100%;
  height: auto;
  position: fixed;
  box-shadow: 0px 3px 6px #00000029;
}

.sideBarDivUl {
  margin-top: 160px;
  width: 100%;
}

.sideBarUl {
  margin: 0;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s;
}

.sideBarUl:hover {
  opacity: 1;
  background-color: #f5f5f5;
}

.sideBarUl:hover .sideBarLi {
  opacity: 1;
  color: #e98a15;
  font-weight: bold;
}

.sideBarSelectedUl {
  margin: 0;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
  background-color: #f5f5f5;
}

.sideBarSelectedUl .sideBarLi {
  color: #e98a15;
  font-weight: bold;
}

.sideBarLi {
  list-style: none;
  font-family: Inter, Regular;
  font-size: 16px;
  color: #303336;
  cursor: pointer;
  margin-left: 40px;
  transition: 0.2s;
}

.codeDialogBtn {
  margin: 20px;
  margin-top: 200px;
}

.codeDialogOuter {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  margin: 5% 10%;
}

.codeDialogOuter h2,
.codeDialogOuter h3 {
  text-align: center;
  color: #63324b;
}

.codeDialogOuter h2 {
  font-size: 30px;
  font-family: Inter, Semi bold;
}

.codeDialogOuter h3 {
  font-size: 24px;
}

.codeDialogOuter p {
  font-family: Inter, Regular;
  color: #303336;
}

.codeDialogInner {
  /* Box holds the QR and manual code, split horizontally */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.manualCode {
  font-size: 24px;
  color: #3c112b;
  border: 1px solid #3c112b;
  height: 70px;
  border-radius: 35px;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20%;
  font-family: Inter;
  padding-left: 5%;
  padding-right: 5%;
}

.sideBarDivCode {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 70px;
}
