.resetPasswordDiv {
  margin-top: 8%;
  margin-left: 18px;
  width: 290px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
}

.resetPasswordInput {
  width: 30px;
  height: 50px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1.5px solid #303336;
  font-size: 40px;
  text-align: center;
  font-family: Roboto;
  color: #e98a15;
}

.resetPasswordInputFocus {
  width: 30px;
  height: 50px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1.5px solid #e98a15;
  font-size: 40px;
  text-align: center;
  font-family: Roboto;
  color: #e98a15;
}

.resetPasswordInput:focus {
  outline: none;
}

.resetPasswordInputFocus:focus {
  outline: none;
}

.resetPasswordInputError:focus {
  outline: none;
}

.resetPasswordInputError {
  width: 30px;
  height: 50px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1.5px solid #c51111;
  font-size: 40px;
  text-align: center;
  font-family: Roboto;
  color: #c51111;
}

.resetPasswordBtnDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.resetPasswordCodeParagraph {
  font-family: 'Roboto';
  font-size: 12px;
  margin-left: 25px;
  position: relative;
  top: 32px;
  color: #6a6a6a;
}

.resetPasswordErrorDiv {
  margin-left: 24px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  color: #c51111;
}

.resetPasswordErrorDivNone {
  margin-left: 24px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  opacity: 0;
}

.resetPasswordErrorText {
  margin-left: 2px;
  color: #c51111;
  font-family: Roboto, Regular;
  font-weight: 450;
  font-size: 12px;
}

.divSpamAlert {
  width: 400px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.spamAlertText {
  margin: 0;
  width: 100%;
  font-family: Roboto, Regular;
  color: '#303336';
}

.spamAlertIcon {
  margin-right: 5px;
  color: '#303336';
}

.sendOtpBtnDiv {
  width: 480px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 30px;
}

.mainDivSendOtp {
  width: 60%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3%;
  text-align: left;
  font-weight: 300;
  font-family: Roboto, Regular;
  font-size: 14px;
  color: #6e6e6e;
  position: relative;
}

.resetPasswordResend {
  border: none;
  background-color: white;
  margin-right: 15px;
  color: #e98a15;
  font-family: Roboto, Regular;
  font-size: 16px;
  cursor: pointer;
}

.resetPasswordResendDisabled {
  border: none;
  background-color: white;
  margin-right: 15px;
  color: gray;
  font-family: Roboto, Regular;
  font-size: 16px;
  cursor: pointer;
}
