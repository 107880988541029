.rootLogin {
  position: relative;
  width: 40%;
  height: 20%;
  border-width: 0;
  box-shadow: 0px 3px 6px #00000029;
}

.mainView {
  display: flex;
  justify-content: center;
  justify-self: center;
  z-index: 3;
  width: 100%;
  margin-top: 150px;
  height: 100%;
}

.loginMainViewTitle {
  width: 94%;
  margin: 0 auto;
}

.loginMainTitle {
  text-align: left;
  align-self: center;
  font-family: Inter;
  font-size: 1.15em;
  color: #303336;
  font-weight: 600;
  margin: 0px;
  font-size: 20px;
}

.otpMainTitle {
  text-align: left;
  width: 65%;
  font-family: Inter;
  font-size: 1.15em;
  color: #303336;
  font-weight: 600;
  margin: 0px;
  font-size: 20px;
}

.formLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-left: 10px;
  padding-top: 5%;
  padding-bottom: 5%;
}

.divLabelLogin {
  width: 60%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  margin-left: 3%;
  text-align: left;
  font-weight: 300;
  font-family: Roboto, Regular;
  font-size: 14px;
  color: #6e6e6e;
  position: relative;
}

.buttonsDivLogin {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  top: 20px;
  margin-right: 32px;
  align-items: center;
  justify-content: flex-end;
}

.forgotPasswordDiv {
  width: 150px;
  font-family: Roboto, Regular;
  color: #e98a15;
  font-size: 14px;
  cursor: pointer;
}

.loginGoBackDiv {
  margin: 0;
  margin-left: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 15%;
  color: #6a6a6a;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.loginGoBackParagraph {
  padding-left: 6px;
}

.loginErrorDiv {
  position: absolute;
  top: 45px;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  color: #c51111;
}

.loginErrorDivNone {
  display: none;
}

.loginErrorText {
  margin-left: 2px;
  color: #c51111;
  font-family: Roboto, Regular;
  font-weight: 450;
  font-size: 12px;
}

.loginFormDiv {
  position: relative;
}

.loginEyeIcon {
  position: absolute;
  top: 20px;
  right: 5px;
  cursor: pointer;
}

/* .inputLogin {
    border-top: white;
    border-right: white;
    border-left: white;
    border-bottom-color: #303336;
    border-width: 1px;
    font-family: Roboto, Regular;
    color:#303336;
    font-size: 16px;
}

.inputLogin:focus {
    outline-width: 0;
    color:#E98A15;
    border-bottom-color:#E98A15;
}

.inputLogin::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
/* color:#303336;
    opacity: 1; /* Firefox */
/* }
.inputLogin:-ms-input-placeholder { 
    color:#303336;
}
  
.inputLogin::-ms-input-placeholder {
    color:#303336;
}

.inputLogin:focus::-webkit-input-placeholder { 
    color: #E98A15; 
}

.inputLogin:focus:-moz-placeholder {
    color: #E98A15;
}​  
*/

@media only screen and (max-width: 1265px) {
  .rootLogin {
    width: 50%;
  }
}

@media only screen and (max-width: 1035px) {
  .rootLogin {
    width: 60%;
  }
}

@media only screen and (max-width: 880px) {
  .rootLogin {
    width: 70%;
  }
}

@media only screen and (max-width: 750px) {
  .rootLogin {
    width: 80%;
  }
}
