/* Modal Content/Box */
.modalFirstLoginContent {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalFirstLogin {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.modalSuccessConfirm {
  width: 142px;
  border-radius: 24px;
  padding: 12px 10px 12px 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: #e98a15;
  color: white;
  margin: 0;
  border-width: 0;
  cursor: pointer;
}

.modalSuccessConfirm:hover {
  opacity: 1;
  background: #ba6f12;
}

.modalFirstLoginMainTitle {
  color: #63324b;
  font-size: 30px;
  font-family: Inter, Semi Bold;
  margin-bottom: 0;
}

.modalFirstLoginText {
  font-size: 15px;
  color: #303336;
  margin-bottom: 0;
  font-family: Inter;
}

.modalFirstLoginLink {
  padding-top: 20px;
  font-family: Inter, Semi;
  color: #63324b;
}
