/* Modal Content/Box */
.modalTurnosContent {
  background-color: #fefefe;
  margin: 3% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 430px;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  transition: 0.2s;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #cecece;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(168, 167, 167);
  opacity: 1;
}

.modalTurnosTitle {
  color: #63324b;
  font-size: 30px;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  text-align: left;
  margin: 10px 0px 0px 10px;
}

.modalTurnosParagraph {
  color: #303336;
  font-size: 16px;
  width: 80%;
  font-family: Inter;
  text-align: left;
  margin: 10px 0px 25px 10px;
}

.modalTurnosDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-left: 10px; */
  width: 100%;
}

.modalTurnosDivInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 45%; */
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}

.modalTurnosInput {
  width: 100%;
  border-radius: 16px;
  border: 1px solid #303336;
  height: 25px;
  position: relative;
  color: #303336;
  font-family: Inter, Regular;
  padding-left: 30px;
  z-index: 2;
}

.modalTurnosInput:focus {
  outline: none;
}

.modalTurnosLabel {
  z-index: 20;
  position: relative;
  left: 25px;
}

.modalTurnosDivOne {
  width: 35%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalTurnosDivRow {
  display: flex;
  flex-direction: row;
}

.modalTurnosInputParagraph {
  color: #6a6a6a;
  font-family: Roboto;
  font-size: 12px;
  align-self: flex-start;
}

.modalTurnosScheduleInput {
  width: 60px;
  font-family: Roboto;
  height: 60px;
  font-size: 40px;
  border-radius: 16px;
  border: 1px solid #6a6a6a;
  text-align: center;
  margin-right: 10px;
  color: #303336;
}

.modalTurnosScheduleInput::placeholder {
  color: #303336;
}

.modalTurnosScheduleInput:focus {
  outline-width: 0;
}

/* APPROACH 1 */

.modalTurnosScheduleInputGray {
  width: 60px;
  font-family: Roboto;
  height: 60px;
  font-size: 40px;
  border-radius: 16px;
  border: 1px solid #6a6a6a;
  text-align: center;
  margin-right: 10px;
  color: #87898b;
}

.modalTurnosScheduleInputGray::placeholder {
  color: #87898b;
}

.modalTurnosScheduleInputGray:focus {
  outline-width: 0;
}

/* ------------ */

.modalShiftsError {
  color: red;
  font-size: 16px;
  font-family: Inter;
  text-align: left;
  align-self: flex-end;
  margin: 20px 0px 10px 0px;
}

.modalShiftsErrorNone {
  opacity: 0;
  margin: 20px 0px 10px 0px;
}

.modalTurnosInputName {
  width: 80%;
  border: none;
  border-bottom: 1px solid #303336;
  outline: none;
  font-family: Inter, Semi Bold;
  font-size: 18px;
  color: #303336;
  margin: 2%;
}

.modalTurnosInputName:focus {
  border-bottom: 2px solid #e98a15;
}

.modalTurnosInputName::placeholder {
  color: #303336;
}

/* Modal confirmar contraseña */

.modalEditTurnoContent {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modalEditTurnoTitle {
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 24px;
  margin-left: 10px;
  align-self: center;
}

.modalEditTurnoInput {
  color: #303336;
  margin-top: 7%;
  font-family: Roboto;
  font-size: 16px;
  border-top: white;
  border-right: white;
  border-left: white;
  border-bottom-color: #303336;
  border-width: 1px;
  width: 50%;
  margin-left: 10px;
  position: relative;
}

.modalEditTurnoInput:focus {
  outline-width: 0;
}

.modalEditTurnoInput::placeholder {
  color: #303336;
}

.modalEditTurnoPasswordNone {
  opacity: 0;
}

.modalEditTurnoPassword {
  color: red;
  font-family: Inter;
  font-size: 14px;
  align-self: center;
}

.modalErrorNumber {
  width: 142px;
  border-radius: 24px;
  padding: 12px 10px 12px 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: #e98a15;
  color: white;
  margin: 0;
  border-width: 0;
  cursor: pointer;
}

.modalErrorNumber:hover {
  opacity: 1;
  background: #ba6f12;
}

.modalTurnosScheduleInput::-webkit-outer-spin-button,
.modalTurnosScheduleInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.modalTurnosScheduleInput[type='number'] {
  -moz-appearance: textfield;
}

.modalTurnosScheduleInputGray::-webkit-outer-spin-button,
.modalTurnosScheduleInputGray::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.modalTurnosScheduleInputGray[type='number'] {
  -moz-appearance: textfield;
}
