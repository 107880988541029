.QRResultImgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.QRResultLogo {
  width: 200px;
}

.QRResultMainTitle {
  font-size: 22px;
  color: #440927;
  padding-bottom: 0;
  margin-bottom: 0;
  padding-top: 0;
  margin-top: 0;
}

.QRResultConfirm {
  width: 180px;
  border: none;
  background-color: #440927;
  color: white;
  border-radius: 10px;
  height: 40px;
  font-size: 16px;
  margin-bottom: 30px;
  cursor: pointer;
}

.QRResultMainSubtitle {
  color: #440927;
  font-size: 20px;
  font-style: italic;
  width: 50%;
  margin-top: 0;
  padding-top: 0;
}
