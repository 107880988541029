.historialAccordionDivTitle {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.historialAccordionDateDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.historialAccordionDateOne {
  font-family: Inter;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.historialAccordionDateTwo {
  font-family: Inter;
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
}

.historialAccordionShift {
  width: 10%;
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 30px;
  font-weight: bold;
}

.historialAccordionTotal {
  /* width: 25%; */
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.historialAccordionTotalText {
  font-family: Inter;
  font-weight: bold;
  font-size: 20px;
  margin-top: 0px;
  position: relative;
  top: 5px;
}

.historialAccordionState {
  /* width: 25%; */
  text-align: left;
}

.historialAccordionDetailsDiv {
  width: 100%;
}

.historialAccordionDetailsInfo {
  color: #837575;
  font-family: Inter, Semi Bold;
  font-size: 14px;
  font-weight: 600;
}

.historialAccordionDetailsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.historialAccordionDetailsText {
  color: #6a6a6a;
  font-family: Inter;
  font-size: 16px;
}

.historialAccordionDetailsBold {
  color: #6a6a6a;
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
}

.historialAccordionDetailsDivider {
  height: 1px;
  width: 100%;
  background-color: rgb(167, 167, 167);
}

.historialAccordionIcon {
  height: 100%;
  margin-right: 50px;
  display: flex;
}
