/* Modal Content/Box */
.modalColaboradoresContent {
  background-color: #fefefe;
  margin: 3% auto;
  padding: 20px;
  height: 75%;
  border: 1px solid #888;
  width: 430px;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

.modalColaboradoresContentFirstLogin {
  background-color: #fefefe;
  margin: 3% auto;
  padding: 20px;
  height: 60%;
  border: 1px solid #888;
  width: 430px;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  transition: 0.2s;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #cecece;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(168, 167, 167);
  opacity: 1;
}

.modalColaboradoresTitle {
  color: #63324b;
  font-size: 30px;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  text-align: left;
  margin: 10px 0px 25px 10px;
}

.modalColaboradoresSubtitle {
  margin: 10px 0px 25px 10px;
  width: 70%;
  color: #303336;
  font-size: 15px;
  font-family: Inter, Regular;
}

.modalColaboradoresDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  width: 70%;
}

.modalColaboradoresDivInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}

.modalColaboradoresInput {
  width: 100%;
  border-radius: 16px;
  border: 1px solid transparent;
  height: 25px;
  position: relative;
  box-shadow: 2px 2px 4px #00000029;
  color: #303336;
  font-family: Inter, Regular;
  padding-left: 30px;
  z-index: 2;
}

.modalColaboradoresInput:focus {
  outline: none;
  border: 1px solid #e98a15;
  border-radius: 16px;
}

.modalColaboradoresLabel {
  z-index: 20;
  position: relative;
  left: 25px;
}

.itemAndButtonContainer {
  display: flex;
}

.itemImgInactive {
  opacity: 0.5;
}

.modalColaboradoresNoAvailableDiv {
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
}

.modalColaboradoresNoAvailableText {
  padding: 0px 0px 0px 20px;
  color: #303336;
  font-family: Inter, Semi Bold;
}

.modalColaboradoresBtn {
  background: #e98a15;
  border-radius: 24px;
  color: white;
  width: 126px;
  height: 52px;
  border-style: none;
  font-size: 16px;
  margin-top: 50px;
}

.modalColaboradoresBtnDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
