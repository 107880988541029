.selectShiftDropdownDiv {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.selectShiftDropdownSelect {
  border-color: #00000029;
  border-width: 0.5px;
  padding: 10px;
  border-radius: 18px;
  box-shadow: 0px 3px 6px #00000029;
}

.selectShiftDropdownOption {
  font-family: Inter, Semi Bold;
  font-weight: bold;
  font-size: 20px;
  color: #303336;
}
