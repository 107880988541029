/* Modal Content/Box */
.modalUnauthorizedContent {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow: none;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalUnauthorized {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
}

.modalUnauthorizedMainTitle {
  color: #63324b;
  font-size: 24px;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-left: 20px;
}

.modalUnauthorizedText {
  font-size: 18px;
  color: #333639;
  font-weight: 400;
  margin-left: 20px;
}

.modalUnauthorizedImg {
  width: 120px;
  margin-top: 30px;
  align-self: center;
}

.modalUnauthorizedLink {
  font-size: 18px;
  margin-left: 20px;
}
