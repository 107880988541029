/* Modal Content/Box */
.modalConfirmAddSectorTurnoContent {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modalConfirmAddSectorTurnoTitle {
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 24px;
  margin-left: 10px;
  align-self: center;
}

.modalConfirmAddSectorTurnoInput {
  color: #303336;
  margin-top: 7%;
  font-family: Roboto;
  font-size: 16px;
  border-top: white;
  border-right: white;
  border-left: white;
  border-bottom-color: #303336;
  border-width: 1px;
  width: 50%;
  margin-left: 10px;
  position: relative;
}

.modalConfirmAddSectorTurnoInput:focus {
  outline-width: 0;
}

.modalConfirmAddSectorTurnoInput::placeholder {
  color: #303336;
}

.modalConfirmAddSectorTurnoPasswordNone {
  opacity: 0;
}

.modalConfirmAddSectorTurnoPassword {
  color: red;
  font-family: Inter;
  font-size: 14px;
  align-self: center;
}

.eyeIcon {
  position: absolute;
  top: 20px;
  right: 5px;
  cursor: pointer;
  color: gray;
}
