.cardProcessingTransactionContainer {
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
}

.cardProcessingTransaction {
  width: 570px;
  height: 399px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.cardProcessingTransactionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
}

.cardProcessingTransactionContent img {
  height: 88px;
}

.cardProcessingTransactionTitle {
  font-size: 24px;
  color: #63324b;
  font-weight: bold;
}

.cardProcessingTransactionContent p {
  color: #333639;
}

.linkProcessingTransaction {
  width: 167px;
  height: 50px;
  background-color: #e98a15;
  border-radius: 24px;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
