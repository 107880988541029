.turnosYSectoresCardContainerDiv {
  width: 82%;
  position: relative;
  left: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.turnosYSectoresHeaderDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding-top: 120px;
}

.turnosYSectoresCenterDiv {
  width: 91%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.turnosYSectoresMainDiv {
  /* width: 32%; */
  height: 142px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #00000029;
}

.turnosYSectoresTitleDiv {
  display: flex;
  flex-direction: column;
  margin-left: 28px;
}

.turnosYSectoresCenterTitle {
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 30px;
  margin-bottom: 0;
}

.turnosYSectoresCenterParagraph {
  color: #303336;
  font-family: Inter, Regular;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
}

.turnosYSectoresGoBackDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #6a6a6a;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.turnosYSectoresGoBackParagraph {
  padding-left: 6px;
}

.turnosYSectoresParagraph {
  color: #303336;
  font-family: Inter;
  font-size: 16px;
}

.turnosYSectoresTitle {
  color: #303336;
  font-family: Inter, Semi Bold;
  font-size: 30px;
}

.turnosYSectoresEditar {
  margin-right: 28px;
  align-self: flex-end;
  margin-bottom: 24px;
  color: #e98a15;
  font-size: 16px;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  cursor: pointer;
}

.turnosYSectoresEditar:hover {
  color: #ba6f12;
}

.nuevoTurnoBtn {
  width: 159px;
  height: 40px;
  border-radius: 24px;
  padding: 12px 25px 12px 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: white;
  border-style: solid;
  border-color: #e98a15;
  border-width: 1px;
  color: #e98a15;
  margin: 0;
  cursor: pointer;
}

.nuevoTurnoBtn:hover {
  background-color: #d1d1d133;
}

.turnosYSectoresSaveChanges {
  width: 200px;
  height: 50px;
  border-radius: 24px;
  align-self: flex-end;
  /* padding: 12px 25px 12px 25px; */
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, Semi Bold;
  background-color: #e98a15;
  color: white;
  border: none;
  margin: 0;
  cursor: pointer;
  margin-right: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.turnosYSectoresSaveChanges:hover {
  background-color: #ba6f12;
}

.turnosYSectoresSaveChanges:disabled {
  background-color: #d1d1d1;
}

.turnosYSectoresError {
  color: #c51111;
  width: 91%;
  text-align: right;
  margin-right: 50px;
}
