.QRFormHeaderDiv {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10%;
}

.QRForm {
  height: 80%;
  margin-top: 0;
  padding-top: 0;
  align-self: center;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
}

.QRFormInput {
  text-decoration: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-width: 1px;
  padding: 50px 0 10px 0px;
  text-align: left;
  font-size: 16px;
}

.QRFormInput:focus {
  outline: none;
}

.QRFormInput::placeholder {
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.QRFormInput:first-of-type {
  text-decoration: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-width: 1px;
  padding-top: 0;
  margin-top: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

.QRFormConfirm {
  width: 180px;
  border: none;
  background-color: #440927;
  color: white;
  border-radius: 10px;
  height: 40px;
  font-size: 16px;
  margin-bottom: 15px;
  cursor: pointer;
}

.QRFormConfirmDisabled {
  width: 180px;
  color: #440927;
  background-color: white;
  border-radius: 10px;
  height: 40px;
  font-size: 16px;
  margin-bottom: 15px;
  cursor: pointer;
  border-width: 0.5px;
}
