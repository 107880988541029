/* The Modal (background) */
.modalTip {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 22;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modalTipContent {
  background-color: #fefefe;
  margin: 3% auto;
  /* 15% from the top and centered */
  padding: 20px;
  padding-bottom: 10px;
  border: 1px solid #888;
  width: 35%;
  animation: fadeInModal 0.2s linear forwards;
  opacity: 0;
  transition: 0.2s;
  box-shadow: 0px 3px 6px #00000029;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modalTipParagraph {
  width: 80%;
  color: #303336;
  font-family: Inter, Regular;
  font-size: 16px;
  margin-left: 5%;
}

.modalTipForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-left: 25px;
}

.divLabelTipModal {
  width: 60%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10%;
  text-align: left;
  font-weight: 300;
  font-family: Helvetica;
  font-size: 14px;
  color: #303336;
  position: relative;
}

.inputAmount {
  border-top: white;
  border-right: white;
  border-left: white;
  border-bottom-color: #303336;
  border-width: 1px;
  color: #303336;
  font-size: 24px;
  font-family: Inter, Semi Bold;
  font-weight: 500;
  /* padding-left: 20px; */
}

.inputAmount:focus {
  outline-width: 0;
}

.inputTip {
  border-top: white;
  border-right: white;
  border-left: white;
  border-bottom-color: #303336;
  border-width: 1px;
  color: black;
  font-size: 16px;
  font-family: Inter, Semi Bold;
  font-weight: 500;
}

.inputTip::placeholder {
  color: #303336;
}

.inputTip:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #303336;
}

.inputTip::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #303336;
}

.inputTip:focus::-webkit-input-placeholder {
  color: #303336;
}

.inputTip:focus:-moz-placeholder {
  color: #303336;
}

​ .inputAmount:focus {
  outline-width: 0;
  border: none;
}

.inputTip:focus {
  outline-width: 0;
}

.modalTipMoneySign {
  color: #303336;
  font-size: 24px;
  font-family: Inter, Semi Bold;
  font-weight: 500;
  position: absolute;
  top: -23px;
  left: 2px;
}
