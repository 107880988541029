.getStarted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
}

.getStarted h1 {
  color: #e98a15;
  font-size: 56px;
  margin-top: 56px;
  margin-bottom: 0;
  padding-top: 0;
}

.getStarted p {
  color: #666666;
  font-size: 24px;
}

.getStarted hr {
  color: #e98a15;
  width: 50%;
  margin-top: 56px;
}

.getStarted button {
  width: 229px;
}

@media (max-width: 1000px) {
  .getStarted h1 {
    font-size: 35px;
    margin: 20px 0 10px 0;
  }

  .getStarted p,
  .getStarted button {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .getStarted {
    margin-bottom: 20px;
    margin: 0px 10px 0px 10px;
  }
}

