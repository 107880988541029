.whatIs {
  width: 80%;
}

.whatIs .firstP {
  margin: 0;
}

.whatIsdivSteps li {
  text-decoration: none;
  list-style-type: none;
  text-align: left;
  font: normal normal normal 35px/55px Inter;
  letter-spacing: 0px;
  color: #47484A;
}

h2 .icon {
  position: relative;
  top: 30px;
}

.whatIsdivSteps {
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 20px;
}

.whatIsNumber {
  color: #5f0922;
  font-weight: 900;
  margin-right: 5px;
}

.whatIsPreListTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.whatIsBold {
  font-weight: bold;
  font-size: 28px;
}

.whatIsBoldTitle {
  font-weight: bold;
  font-size: 25px;
  padding-left: 5px;
  margin: 0;
  color: #4b0128;
}

.whatIsSubtitle {
  font-size: 28px;
  font-weight: bold;
  margin: 0 
}

.available {
  font-size: 22px;
  font-style: italic;
}

@media (max-width: 800px) {
  .whatIsSubtitle {
    font-size: 19px;
  }

  .whatIsBold, .whatIsBoldTitle, .available {
    font-size: 17px;
  }
}