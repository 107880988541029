.configuracionDiv {
  width: 100%;
  position: relative;
}

.mainContentConfiguracionDiv {
  min-height: 100vh;
  width: 100%;
}

.configuracionCardContainerDiv {
  width: 82%;
  position: relative;
  left: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.configuracionHeader {
  align-self: center;
  width: 100%;
  margin-left: 10%;
  padding-top: 120px;
}

.configuracionTitle {
  color: #303336;
  font-family: Inter, Semi Bold;
  font-size: 30px;
}

.historialInput:focus {
  outline: none;
}

.configuracionCenterDiv {
  width: 90%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 150px;
}

.configuracionTurnosDiv {
  width: 32%;
  height: 93px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #00000029;
}

.configuracionSectoresDiv {
  width: 32%;
  height: 93px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #00000029;
}

.configuracionDatosDiv {
  width: 32%;
  height: 93px;
  padding-top: 2%;
  padding-bottom: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

.configuracionTitleDiv {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  padding-left: 20px;
  height: 100%;
  width: 70%;
}

.configuracionTitleSubtitleDiv {
  display: flex;
  flex-direction: column;
}

.configuracionCenterTitle {
  color: #63324b;
  font-family: Inter, Semi Bold;
  font-size: 30px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.configuracionCenterSubtitle {
  color: #303336;
  font-family: Inter;
  margin-top: 5px;
}

.configuracionCenterImg {
  height: 51px;
  width: 51px;
}

.configuracionImgDiv {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.configuracionVerMas {
  align-self: center;
  color: #e98a15;
  font-size: 16px;
  font-family: Inter, Semi Bold;
  font-weight: 600;
  cursor: pointer;
}

.configuracionVerMas:hover {
  color: #ba6f12;
}
